import PressListWithAlgolia from '@/components/publications/pressListWithAlgolia';
import SiteWrapper from '@/components/siteWrapper';
import API from '@/lib/api';
import { useSession } from 'next-auth/react';

const IndexPage = ({ siteData }) => {
  const { data: session } = useSession();
  const canViewDoFollowAndSponsored = session?.profile?.can_view_secret_data;
  const useBasePublicationPricing =
    siteData?.attributes?.use_base_publication_pricing;

  return (
    <SiteWrapper siteData={siteData}>
      <PressListWithAlgolia
        siteId={siteData?.id}
        canViewDoFollowAndSponsored={canViewDoFollowAndSponsored}
        showBasePrices={useBasePublicationPricing}
      />
    </SiteWrapper>
  );
};

export async function getStaticPaths() {
  const siteData = await API.sites.find({});
  const sites = siteData?.data?.data;

  const allPaths = sites.reduce(function (result, site) {
    const subdomain = site.attributes.subdomain;
    const customDomain = site.attributes.customDomain;
    if (subdomain) {
      result.push({
        params: { site: subdomain },
      });
    }
    if (customDomain) {
      result.push({
        params: { site: customDomain },
      });
    }
    return result;
  }, []);

  return {
    paths: allPaths,
    fallback: true,
  };
}

export async function getStaticProps(context) {
  const { params, req } = context;
  const { site } = params;

  let siteData;
  if (site.includes('.')) {
    siteData = await API.sites.get({ customDomain: site });
  } else {
    siteData = await API.sites.get({ subdomain: site });
  }

  return {
    props: {
      siteData,
    }, // will be passed to the page component as props
  };
}

export default IndexPage;
